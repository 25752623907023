export enum PreviewType {
    type1 = 'main',
    type2 = 'middle',
    type3 = 'small',
}

export enum ContentType {
    type1 = 'html',
    type2 = 'sf_section',
    type3 = 'journal',
    type4 = 'sf_any_content',
}

export enum PageType {
    typeMain = 'MainPage',
    typeCategory = 'CategoryPage',
    typePress = 'PressPage',
    typeContent = 'ContentPage',
    typeOffer = 'OfferPage',
    typeSubscription = 'SubscriptionPage',
}

export enum Languages {
    ru = 'ru-RU',
    // tj = 'tg-TJ',
    en = 'en-GB',
}

export interface IMultiName {
    ru: string;
    tj: string;
    en: string;
}

export interface ITag {
    id: number;
    type: string;
    display_name: string;
    priority: number;
}

export interface ICategory {
    id: number;
    name: string;
    alias: string;
    contents: IContentInfo[];
}

export interface IContentInfo {
    description: string;
    id: number;
    name: string;
    type: ContentType;
    tags: ITag[];
    file_hash: string;
    previews: IPreview[];
    uidx: string;
    meta_params?: {
        content_type_id: number,
        fat_name: string,
    }
}

export interface IPreview {
    id: number;
    type: PreviewType;
    link: string;
}

export interface IUserData {
    msisdn: number | null;
    profile_id: number | null;
    sid: string;
    tariff: number;
}
export type IUser = IUserData | null

export interface IMappedDataResponse {
    name: string;
    categories: ICategory[];
    musicCategories: ICategory[];
    musicTops: IMusicTop[] | [];
    authorized?: boolean;
    status?: string;
    lang: Languages;
    user: IUser
}

export interface IPlayerInfoPayload {
    trackNumber: number;
    musicAlias: string;
    topNumber: number;
}

export interface IPlayerStatePayload {
    isPlaying: boolean;
}

export interface IPlayerInfo {
    trackNumber: number;
    musicAlias: string;
    isPlaying: boolean;
    topNumber: number;
}

export interface ISection {
    categoryLoaded: boolean;
    categories: {
        // todo почему нельзя в тип lang Language?
        [lang: string]: ICategory[] | [] | undefined;
    };
    musicCategories: ICategory[] | [];
    musicTops: IMusicTop[] | [];
    error?: boolean;
    authorized?: boolean;
    user: IUser
}

export interface IContentText {
    contentLoaded: boolean;
    contents: {
        [key: string]: string;
    }
    textError?: boolean;
}

export interface IPressContentManifest {
    manifestLoaded: boolean;
    manifests: {
        [key: string]: string[];
    }
    manifestError: boolean;
}

export interface ILoadedText {
    textLoaded: boolean;
    text: {
        [key: string]: string;
    }
    error?: boolean;
}

export interface IMusicTop {
    alias: string;
}

export interface ITrackInfo {
    trackName: string;
    playerName: string;
    previewLink: string;
    trackLink: string;
}

export enum AccessStatus {
    Success = 0,
    BadHealth = 1,
    BadCommand = 2,
    BadRequest = 3,
    SubscriptionActive = 4,
    SubscriptionActiveAndSuspended = 5,
    SubscriptionActiveAndNotSuspended = 6,
    SubscriptionNotActive = 7,
    SubscriptionNotFound = 8,
    SubscriptionCreated = 9,
    FailSubscribe = 10,
    FailUnsubscribe = 11,
    SIDNotFound = 12,
    FailSubscribeNoMoney = 13,
    FailSubscribeNotAvailable = 14,
}

export interface IAccessData {
    status: AccessStatus;
    id: number;
    msisdn: number;
    profile_id: number;
    sid: string;
    is_active: boolean;
    is_suspended: boolean;
    is_chargeable: boolean;
    context: string;
    click_params: string;
    try_period: number;
    activation_time: string;
    activation_source: string;
    deactivation_time: string;
    deactivation_source: string;
}

export enum AccessAlias {
    PRIMARY = 'primary',
    SECONDARY = 'secondary',
}

export interface IAccessSectionItem {
    data: IAccessData | null,
    loaded: false,
    error: boolean,
}
export type IAccessSection = Record<AccessAlias, IAccessSectionItem>

export interface IAppStore {
    section: ISection;
    content: IContentText;
    playerInfo: IPlayerInfo;
    pressContent: IPressContentManifest;
    offerText: ILoadedText;
    footerText: ILoadedText;
    subscriptionText: ILoadedText;
    lang: Languages;
    access: IAccessSection
}
