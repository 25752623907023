import React, {Component, createRef} from 'react';

import {ClassNameFormatter, cn} from '@bem-react/classname';
import {classnames} from '@bem-react/classnames';
import {IClassNameProps} from '@bem-react/core';
import MediaQuery from 'react-responsive';
import {Link} from 'react-router-dom';

import {SERVICE_SECONDARY_URL} from '../../constants/constants';
import {ICategory, IUser, Languages, PageType} from '../../types/types';
import {CloseButton} from '../Buttons/CloseButton/CLoseButton';
import {HamburgerButton} from '../Buttons/HumburgerButton/HumburgerButton';
import {ReactComponent as LogoPrimary} from '../Icons/logo-primary.svg';
import {ReactComponent as LogoSecondary} from '../Icons/logo-secondary.svg';
import {ReactComponent as User} from '../Icons/user.svg';
import {Menu} from '../Menu/Menu';
import {UserPanel} from '../UserPanel/UserPanel';

import './Header.css';

// import { ENG_LANG, RUS_LANG } from '../../constants/constants';

export interface IHeaderState {
    open: boolean;
    selectOpen: boolean;
    isOpenUserPanel: boolean;
}

export interface IHeaderDispatchProps {
    onChange(lang: Languages): void;
}

export interface IHeaderStateProps {
    page: string;
    categoriesData: ICategory[];
    categoryAlias?: string;
    language: string;
    authorized?: boolean
    whiteTheme?: boolean;
    user: IUser
}

export interface IHeaderProps extends IClassNameProps, IHeaderStateProps, IHeaderDispatchProps {
}

const header: ClassNameFormatter = cn('Header');

export class Header extends Component <IHeaderProps, IHeaderState> {
    state: IHeaderState = {
        open: false,
        selectOpen: false,
        isOpenUserPanel: false,
    };

    userPanelButtonRef = createRef<HTMLButtonElement>()
    userPanelRef = createRef<HTMLDivElement>()

    toggleMenu = () => this.setState({open: !this.state.open});
    closeMenu = () => this.setState({open: false});
    toggleUserPanel = () => this.setState({isOpenUserPanel: !this.state.isOpenUserPanel});
    closeUserPanel = () => this.setState({isOpenUserPanel: false});

    changeLang = (lang: Languages) => {
        const {onChange} = this.props;

        onChange(lang);
        this.setState({selectOpen: false});

    }

    userPanelClickListener = (ev: MouseEvent) => {
        if (
            this.userPanelButtonRef.current
            && this.userPanelRef.current
            && this.state.isOpenUserPanel
        ) {
            if (
                !this.userPanelButtonRef.current.contains(ev.target as Node)
                && !this.userPanelRef.current.contains(ev.target as Node)
            ) {
                this.closeUserPanel();
            }
        }
    }

    componentDidMount() {
        window.addEventListener('click', this.userPanelClickListener);
    }

    componentWillUnmount() {
        window.removeEventListener('click', this.userPanelClickListener);
    }

    render() {
        const {open, selectOpen, isOpenUserPanel} = this.state;
        const {categoriesData, page, categoryAlias, language, whiteTheme, user} = this.props;

        return (
            <header
                className={classnames(
                    header({ofPage: page, theme: categoryAlias, visible: open}),
                    this.props.className)}>
                <div className={classnames(header('Wrapper'), 'Wrapper')}>
                    <div className={header('LogoContainer')}>
                        <Link to='/' className={header('LogoLink')}>
                            <LogoPrimary className={header('Logo')}/>
                        </Link>
                        <a
                            href={SERVICE_SECONDARY_URL}
                            className={header('LogoLink', { white: (page === PageType.typeCategory) && !open })}
                        >
                            <LogoSecondary className={header('Logo')}/>
                        </a>
                    </div>
                    <div className={header('ButtonWrapper')}>
                        <HamburgerButton onClick={this.toggleMenu}
                                         className={
                                             classnames(header('HamburgerButton', {visible: !this.state.open}))}/>
                        <CloseButton onClose={this.toggleMenu}
                                     className={
                                         classnames(header('CloseButton', {visible: this.state.open}))}/>
                    </div>
                    <Menu
                        className={classnames(header('Menu', {visible: open}))}
                        page={page}
                        categoriesData={categoriesData}
                        onClose={this.closeMenu}
                    />
                    {user ? (
                        <MediaQuery minWidth={'1200px'}>
                            <div className={header('UserPanelWrapper')}>
                                <button
                                    ref={this.userPanelButtonRef}
                                    className={header('UserPanelButton')}
                                    onClick={this.toggleUserPanel}
                                    type={'button'}
                                >
                                    <User className={header('UserPanelIcon', {open: isOpenUserPanel})}/>
                                </button>
                                {isOpenUserPanel ? (
                                    <UserPanel ref={this.userPanelRef} className={header('UserPanel')} />
                                ) : null}
                            </div>
                        </MediaQuery>
                    ) : null}
                    {/*<div className={header('Select')}>*/}
                    {/*    <div*/}
                    {/*        className={header('SelectItem', {state: 'selected'})}*/}
                    {/*        onClick={() => this.setState({ selectOpen: !this.state.selectOpen })}*/}
                    {/*    >*/}
                    {/*        {selectedLanguage}*/}
                    {/*    </div>*/}
                    {/*    <ul className={header('SelectList', {visible: selectOpen})}>*/}
                    {/*        <li*/}
                    {/*            className={header('SelectItem', {active: language === Languages.ru})}*/}
                    {/*            onClick={() => {*/}
                    {/*                this.changeLang(Languages.ru);*/}
                    {/*            }}>*/}
                    {/*            <span>{RUS_LANG}</span>*/}
                    {/*        </li>*/}
                    {/*        <li*/}
                    {/*            className={header('SelectItem', {active: language === Languages.tj})}*/}
                    {/*            onClick={() => {*/}
                    {/*                this.changeLang(Languages.tj);*/}
                    {/*            }}>*/}
                    {/*            <span>{TJ_LANG}</span>*/}
                    {/*        </li>*/}
                    {/*        <li*/}
                    {/*            className={header('SelectItem', {active: language === Languages.en})}*/}
                    {/*            onClick={() => {*/}
                    {/*                this.changeLang(Languages.en);*/}
                    {/*            }}>*/}
                    {/*            <span>{ENG_LANG}</span>*/}
                    {/*        </li>*/}
                    {/*    </ul>*/}
                    {/*</div>*/}
                </div>
            </header>
        );
    }
}
